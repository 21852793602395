<template>
  <div class="swiper-container">
    <div class="swiper-title">{{ currSubData.label }}操作说明</div>
    <!-- page -->
    <div class="swiper-top-page">
      <div class="left">
        <span class="curr-top-page">{{ currSwiperIndex }}</span>
        <span class="total-top-page">/{{ currSubData.pages.length }}</span>
      </div>
    </div>
    <div class="swiper-main">
      <Swiper :modules="modules" ref="mySwiper" class="swiper" :options="swiperOption" @slideChange="changeSwiperIndex">
        <SwiperSlide v-for="(v, i) in currSubData.pages" :key="'swiper' + i">
          <!-- <div class="swiper-main-box"> -->
          <div class="swiper-main-left">
            <div class="main-title">{{ v.title }}</div>
            <div class="main-des">{{ v.content }}</div>
          </div>
          <div class="swiper-main-right">
            <!-- 图片或者视频 -->
            <template v-if="!v.media.url">
              <!-- <img src="" alt=""> -->
              <div>
                <span>暂无图片</span>
              </div>
            </template>
            <template v-else>
              <div v-if="['mp4'].includes(v.media.type)">
                <video
                  id="video"
                  controlslist="nodownload noremoteplayback"
                  controls="controls"
                  ref="video"
                  preload="auto"
                  :src="v.media.url"
                  type="video/mp4"
                ></video>
              </div>
              <img v-else :src="v.media.url" alt="" />
            </template>
          </div>
          <!-- </div> -->
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapState } from 'vuex';
import 'swiper/css/swiper.min.css';
export default {
  props: {
    currSwiperIndex: {
      type: Number,
    },
  },
  data() {
    return {
      swiperObj: null,
      modules: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            console.log(index, className);
            return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`;
          },
          paginationRender(swiper, paginationEl) {
            console.log(swiper, paginationEl);
          },
        },
        slideChangeTransitionEnd() {
          this.swipe(this);
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState({
      currSubData: state => state.course.currSubData,
    }),
  },
  methods: {
    changeSwiperIndex() {
      this.$emit('changePageIndex', this.$refs.mySwiper.$swiper.realIndex + 1);
    },
  },
};
</script>

<style></style>
