<template>
  <div class="course-main" v-if="currPageData">
    <div class="course-main-title">
      <div class="course-main-title-input">
        <el-input placeholder="请输入教程名称" v-model.trim="currSubData.label"> </el-input>
      </div>
      <div class="course-btn">
        <div class="btn del" @click="handleDelCourse(0, currSubData.id)">删除</div>
        <div class="btn review" @click="togglePreview">预览</div>
        <div class="btn save" @click="saveCourse">保存</div>
      </div>
    </div>
    <!-- 教程主体内容 -->
    <div class="course-main-box">
      <div class="box-left">
        <div class="course-page">
          <div class="course-page-main">
            <span class="currPage">{{ currPageIndex }}</span>
            /
            <span class="pages">{{ currSubData.pages.length }}</span>
          </div>
          <i class="icon-delete" v-if="currSubData.pages.length > 1" title="删除" @click="handleDelCourse(1)"></i>
        </div>
        <!-- 内容输入 -->
        <div class="course-content">
          <div class="con-title">
            <el-input v-model="currPageData.title" placeholder="请输入标题"></el-input>
            <el-input type="textarea" :rows="12" resize="none" placeholder="请输入内容" v-model="currPageData.content">
            </el-input>
            <!-- 表情，图片 -->
            <!-- <div class="emo-box">
              <div class="emo"><img src="../../../assets/image/icon_course/face@2x.png" alt="" /></div>
              <div class="emo"><img src="../../../assets/image/icon_course/font@2x.png" alt="" /></div>
              <div class="emo"><img src="../../../assets/image/icon_course/image@2x.png" alt="" /></div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="box-right">
        <div
          class="resources"
          v-loading="isUploading"
          :element-loading-text="`正在上传中...${uploadProgress}%`"
          element-loading-spinner="el-icon-loading"
        >
          <div v-if="currPageData.media.url" class="avatar-wrapper">
            <img :src="currPageData.media.url" class="avatar" />
          </div>
          <el-upload
            v-else
            class="upload-btn"
            drag
            action=""
            :show-file-list="false"
            :http-request="uploadCoverData"
            multiple
          >
            <div>
              <img :src="uploadIcon" alt="" />
              <div class="el-upload__text"><em>点击上传图片/视频/GIF动图</em></div>
            </div>
          </el-upload>
        </div>
        <div class="resources-tips">
          <p>支持上传图片、视频和GIF动图 ，建议上传宽842px尺寸图片</p>
          <el-upload
            v-if="currPageData.media.url"
            class="upload-btn"
            drag
            action=""
            :show-file-list="false"
            :http-request="uploadCoverData"
            multiple
          >
            <div>
              <span>重新上传</span>
            </div>
          </el-upload>
        </div>
      </div>
    </div>

    <!-- 下一页 -->
    <div class="pages-box">
      <div class="edit-page">
        <span class="curr-page">第{{ currPageIndex }}页</span>
        <el-button size="small" @click="toggleSortModal">编辑排序</el-button>
      </div>
      <div class="page-btn">
        <el-button :disabled="!getShowPrevAuth" @click="changePageAndData(0)">上一页</el-button>
        <el-button :disabled="!getShowNextAuth" @click="changePageAndData(1)">下一页</el-button>
        <el-button type="primary" @click="addPage">添加下一页</el-button>
      </div>
    </div>

    <el-dialog append-to-body title="编辑排序" :visible.sync="canShowSort" width="30%">
      <div class="simple-page">
        <Container @drop="onDrop">
          <Draggable v-for="item in currDropSort" :key="item.id">
            <div class="draggable-item">
              <span>{{ item.title }}</span>
            </div>
          </Draggable>
        </Container>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="saveSort">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog append-to-body title="" custom-class="preview-modal" :visible.sync="canShowPreview" width="1200px">
      <div class="swiper-wrapper">
        <div
          class="swiper-button-custom swiper-button-prev"
          @click="toggleSwiperIndex('prev')"
          slot="button-prev"
        ></div>
        <MainSwiper :currSwiperIndex="currSwiperIndex" @changePageIndex="selectSwiperIndex"></MainSwiper>
        <div
          class="swiper-button-custom swiper-button-next"
          @click="toggleSwiperIndex('next')"
          slot="button-next"
        ></div>
      </div>
      <div>
        <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';
import { applyDrag } from '@/utils/utils';
import uploadPath from '@/assets/image/upload.png';
import MainSwiper from './main-swiper.vue';
export default {
  name: 'course',
  data() {
    return {
      uploadIcon: uploadPath,
      isUploading: false,
      canShowSort: false,
      currDropSort: [],
      canShowPreview: false,
      currSwiperIndex: 1,
    };
  },
  components: { Container, Draggable, MainSwiper },
  watch: {
    'currSubData.pages': {
      handler() {
        if (this.currSubData) {
          this.currDropSort = JSON.parse(JSON.stringify(this.currSubData.pages));
        }
      },
      immediate: true,
    },
  },
  computed: {
    getPageIndex() {
      if (this.currPageData) {
        return this.currSubData.pages.findIndex(v => v.id === this.currPageData.id) + 1;
      } else {
        return 1;
      }
    },
    getShowPrevAuth() {
      return (
        this.currSubData.pages.length > 1 &&
        this.currSubData.pages.findIndex(v => v.id === this.currPageData.id) + 1 >= 1 &&
        this.currPageIndex !== 1
      );
    },
    getShowNextAuth() {
      return this.currSubData.pages.length > 1 && this.currPageIndex < this.currSubData.pages.length;
    },
    ...mapState({
      currSubData: state => state.course.currSubData,
      currPageData: state => state.course.currPageData,
      currPageIndex: state => state.course.currPageIndex,
      uploadProgress: state => state.course.uploadProgress,
    }),
  },
  methods: {
    selectSwiperIndex(value) {
      this.currSwiperIndex = value;
    },
    toggleSwiperIndex(btnType) {
      if (this.currSwiperIndex < this.currSubData.pages.length && this.currSwiperIndex >= 1) {
        switch (btnType) {
          case 'prev':
            this.currSwiperIndex--;
            break;
          case 'next':
            this.currSwiperIndex++;
            break;
        }
      }
    },
    togglePreview() {
      this.canShowPreview = !this.canShowPreview;
    },
    handleDelCourse(type = 1, id) {
      // type 0 教程 1 单页
      const text =
        type === 0
          ? '当前执行删除教程操作，其所有页都会被删除。确定删除吗？'
          : '当前执行删除本页内容操作。确认删除吗？';
      this.$confirm(text, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(async () => {
          if (type === 0) {
            const res = await this.delCourseData({ id: id.toString() });
            if (res.data.success) {
              this.resetSubAndPageData();
            }
          } else if (type === 1) {
            // 移除当前页，再保存
            this.changePageDataAndIndex();
            this.saveCourse();
          }
        })
        .catch(err => {
          console.log(err);
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    onDrop(dropResult) {
      const arr = applyDrag(this.currDropSort, dropResult);
      this.currDropSort = JSON.parse(JSON.stringify(arr));
    },
    saveSort() {
      console.log(this.currDropSort);
      this.changePagesSort(this.currDropSort);
      this.toggleSortModal();
    },
    showSortModal() {
      this.toggleSortModal();
      this.currDropSort = JSON.parse(JSON.stringify(this.currSubData.pages));
    },
    toggleSortModal() {
      if (!this.currDropSort[0].title) {
        this.$message.warning('内容为空');
        return;
      }
      this.canShowSort = !this.canShowSort;
    },
    async uploadCoverData(value) {
      let formData = new FormData();
      formData.append('uploadfile', value.file);
      this.isUploading = true;
      const res = await this.uploadCover(formData);
      if (res.data.success) {
        this.isUploading = false;
        this.toggleUploadProgress(0);
      }
    },
    saveCourse() {
      this.postPageData();
    },
    changePageAndData(num) {
      if (num === 0) {
        this.togglePageIndex(this.currPageIndex - 1);
      }
      if (num === 1) {
        this.togglePageIndex(this.currPageIndex + 1);
      }
      this.togglePageData();
    },
    addPage() {
      // 添加新页 改页码 改当前页内容
      this.addCoursePage();
      this.addPageIndex();
      this.togglePageData();
    },
    ...mapMutations('course', [
      'resetSubAndPageData',
      'addCoursePage',
      'addPageIndex',
      'changePagesSort',
      'changePageDataAndIndex',
      'togglePageData',
      'togglePageIndex',
      'toggleUploadProgress',
    ]),
    ...mapActions('course', ['uploadCover', 'postPageData', 'delCourseData']),
  },
};
</script>

<style lang="less">
// @import url('~@/assets/style/task/tutorialManage.less');
</style>
