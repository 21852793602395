<template>
  <div class="tutorial-manage-main">
    <div class="main-title">
      <div class="title">
        <h3>教程管理</h3>
        <span>云平台教程</span>
      </div>
      <!-- <div class="preview">
        <div class="preview-btn"></div>
        <span>教程预览</span>
      </div> -->
    </div>
    <!-- 主要内容 -->
    <div class="manage-box">
      <div class="manage-box-left">
        <tree></tree>
      </div>
      <div class="manage-box-right">
        <course></course>
      </div>
    </div>
  </div>
</template>

<script>
import tree from '../../../components/task/tutorialManage/tree.vue';
import course from '../../../components/task/tutorialManage/course.vue';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'tutorialManage',
  components: {
    tree,
    course,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      hasSelect: state => (state.course.currData ? true : false),
    }),
  },
  created() {
    this.getAllCourseData();
  },
  methods: {
    ...mapActions('course', ['getAllCourseData']),
  },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/task/tutorialManage.less';
</style>
