<template>
  <div class="tree-main-box">
    <div class="tree-search">
      <el-input placeholder="搜索" prefix-icon="el-icon-search" v-model.trim="key" clearable @change="getAllCourseData">
      </el-input>
    </div>
    <div class="tree-main">
      <div class="add-category">
        <h6>教程</h6>
        <div class="add-cate-btn" @click="addBigClass">
          <i class="el-icon-circle-plus"></i>
          <span>添加大类</span>
        </div>
        <!-- <div class="del-cate-btn">
            <span>删除</span> 
        </div> -->
      </div>

      <!-- tree -->
      <el-scrollbar class="custom-scroll" wrap-class="default-scrollbar__wrap">
        <el-tree
          :data="allData"
          ref="tree"
          node-key="id"
          @node-click="handleNodeClick"
          icon-class="el-icon-arrow-right"
        >
          <div
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @mouseenter="showEditOrDel(node, data)"
            @mouseleave="hideEditOrDel(node, data)"
          >
            <p :class="data.class" :title="node.label">{{ node.label }}</p>

            <!-- 删除  编辑按钮 -->
            <i class="icon-edit" v-if="data.isEdit" @click.stop="editClass(node, data)"></i>
            <i class="icon-delete" v-if="data.isDel" @click.stop="delClass(node, data)"></i>
          </div>
        </el-tree>
      </el-scrollbar>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      :title="tipsTitle"
      v-if="tipsVisible"
      :visible.sync="tipsVisible"
      width="400px"
      @open="handleOpen"
      @closed="addName = ''"
      append-to-body
    >
      <!-- 最后一项所出现的添加子项 -->
      <div class="add-container">
        <el-input
          ref="inputRef"
          :placeholder="`请${tipsTitle}名称`"
          v-model.trim="addName"
          clearable
          v-focus
          @keyup.enter.native="saveAdd()"
        >
        </el-input>
        <div class="add-btn">
          <el-button type="info" plain @click="cancelAdd()">取消</el-button>
          <el-button type="primary" @click="saveAdd()">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'tree',
  data() {
    return {
      key: '',
      // 弹窗
      addName: '',
      tipsTitle: '',
      tipsVisible: false,
      currNode: '',
      currData: '',
      currId: 0,
    };
  },
  computed: {
    ...mapState({
      allData: state => state.course.allData,
      currSubData: state => state.course.currSubData,
    }),
  },
  watch: {
    tipsVisible() {
      if (!this.tipsVisible) {
        this.addName = '';
      }
    },
  },
  methods: {
    handleOpen() {
      // console.log(this.$refs.inputRef)
      // this.$nextTick(() => {
      //   this.$refs.inputRef.$el.querySelector('input').focus();
      // })
    },
    async showCurrCourse({ data }) {
      await this.getCourseData({
        id: data.id,
      });
    },
    delClass(node, data) {
      this.currNode = node;
      this.currData = data;
      let text = data.class === 'son' ? '确认删除该教程?' : '确认删除该大类？此删除会把该大类包含的教程删除！';
      this.$confirm(text, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(async () => {
          const res = await this.delCourseData({
            id: data.id,
          });
          if (res.data.success) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1);
            if (data.class === 'big') {
              if(this.currSubData){
                if (this.currSubData.parent_id === data.id) {
                  this.resetSubAndPageData();
                }
              }
              
            } else if (data.class === 'son') {
              if (this.currSubData.id === data.id) {
                this.resetSubAndPageData();
              }
            }
          }
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error',
          });
        });
    },
    editClass(node, data) {
      this.tipsVisible = !this.tipsVisible;
      this.addName = data.label;
      this.currNode = node;
      this.currData = data;
      this.tipsTitle = data.class === 'big' ? '编辑大类' : '编辑子项';
    },
    hideEditOrDel({ data }) {
      data.isDel = false;
      data.isEdit = false;
    },
    showEditOrDel({ data }) {
      if (data.class == 'big') {
        data.isDel = true;
        data.isEdit = true;
      } else if (data.class == 'son') {
        const obj = this.allData.find(v => v.id === data.parent_id);
        obj.children.forEach(v => {
          console.log(v);
          if (v.id === data.id) {
            v.isDel = true;
            v.isEdit = true;
          }
        });
      }
    },
    // 添加大类
    addBigClass() {
      this.tipsVisible = !this.tipsVisible;
      this.tipsTitle = '添加大类';
    },
    // 添加子项
    async saveAdd() {
      if (this.addName.trim() === '') {
        this.$message.error('不能为空');
        return;
      }
      if (this.tipsTitle == '添加大类') {
        const res = await this.addNewCourseData({
          label: this.addName,
        });
        if (res.data.success) {
          this.insertNode('parent', res.data.data);
        }
      } else if (this.tipsTitle == '添加子项') {
        const res = await this.addSubCourseData({
          label: this.addName,
          parent_id: this.currId,
        });

        if (res.data.success) {
          this.insertNode('children', res.data.data);
        }
      } else if (this.tipsTitle.indexOf('编辑') !== -1) {
        const res = await this.editCourseName({
          id: this.currData.id,
          label: this.addName,
        });
        if (res.data.success) {
          this.currData.label = this.addName;
        }
      }
      this.addName = '';
      this.cancelAdd();
    },
    insertNode(text, id) {
      console.log(text, id, this.allData);
      if (text === 'parent') {
        this.allData.push({
          id: id, // 需要新建的大类id
          isDel: false,
          isEdit: false,
          label: this.addName,
          class: 'big',
          children: [
            {
              parent_id: id,
              label: '+添加子项',
              class: 'add-son',
            },
          ],
        });
      } else if (text === 'children') {
        const obj = this.allData.find(v => v.id === this.currData.parent_id);
        obj.children.push({
          id: id, // 需要新建的子类id
          parent_id: this.currData.parent_id,
          isDel: false,
          isEdit: false,
          label: this.addName,
          class: 'son',
          pages: [
            {
              title: '',
              content: '',
              media: {
                url: '',
                type: '',
              },
            },
          ],
        });
      }
      // const num = Math.floor((Math.random()*100)+1)
      // this.$refs.tree.insertBefore(
      //   {
      //     id: num,
      //     parent_id: this.currId,
      //     label: that.addName,
      //     class: 'son',
      //     isDel: false,
      //     lis: [
      //       {
      //         id: 1,
      //         course_id: num,
      //         parent_id: this.currId,
      //         title: '',
      //         des: '',
      //         url: ''
      //       }
      //     ]
      //   },
      //   that.currNode,
      // );
    },
    cancelAdd() {
      this.tipsVisible = !this.tipsVisible;
    },
    handleNodeClick(data, node) {
      console.log('node--click', data, data.label, node);
      this.currNode = node;
      this.currData = data;

      if (data.label == '+添加子项') {
        this.tipsTitle = '添加子项';
        this.tipsVisible = !this.tipsVisible;
        this.currId = data.parent_id;
      }

      if (data.parent_id && data.label !== '+添加子项') {
        this.showCurrCourse({ data });
      }
    },
    ...mapMutations('course', ['resetSubAndPageData']),
    ...mapActions('course', [
      'addNewCourseData',
      'addSubCourseData',
      'delCourseData',
      'editCourseName',
      'getAllCourseData',
      'getCourseData',
    ]),
  },
};
</script>

<style lang="less">
@import url('~@/assets/style/task/tutorialManage.less');
</style>
